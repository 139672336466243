import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';

import Herosection from './components/herosection';
import Gototop from './components/gototop';
import Navbar from './components/navbar';
import Landingpage from './components/landingpage';
import Footer from './components/footer';
import Construction from './components/solution/construction';
import Partners from './components/partners';
import Careers from './components/career';
import Privacy from './components/privacy';
import siteTerms from './components/site-terms';
import LegalOfService from './components/legal-of-service';
import Latest_News from './components/latest-news';
import Accelerator from './components/accelerator';
import Courses from './components/courses';
import WebsiteBot from './components/chatbot';
import CourseProviderDetails from "./components/course-details";
import CourseRegistration from "./components/course-registation";
import OpenAIChatbot from './components/openai-chatbot';
import Dashboard from './components/openai-chatbot/dashboard';
import ChatArea from './components/openai-chatbot/chatarea';

import { useEffect } from 'react';

function App() {

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  return (
    <HashRouter>
      <Navbar />
      <WebsiteBot />
      <Gototop />
      <Routes>
        <Route path='/' Component={Herosection} />
        <Route path='/landing' Component={Landingpage} />
        <Route path='/construction' Component={Construction} />
        <Route path='/partners' Component={Partners} />
        <Route path='/career' Component={Careers} />
        <Route path='/privacy' Component={Privacy} />
        <Route path='/site-terms' Component={siteTerms} />
        <Route path='/legal-of-service' Component={LegalOfService} />
        <Route path='/latest-news' Component={Latest_News} />
        <Route path='/courses' Component={Courses} />
        <Route path="/course-provider/:id" element={<CourseProviderDetails />} />
        <Route path="/course-registration/:id" element={<CourseRegistration />} />
        <Route path='/accelerator' Component={Accelerator} />

        {/* Main route for OpenAI Chatbot with nested routes for ChatArea and Dashboard */}
        <Route path='/openai-chatbot' element={<OpenAIChatbot />}>
          <Route index element={<ChatArea />} />
          <Route path="chatarea" element={<ChatArea />} />
          <Route path="dashboard" element={<Dashboard />} />
        </Route>
        
      </Routes>
      <Footer />
    </HashRouter>
  );
}

export default App;
